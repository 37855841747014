<template>
  <div class="map-bg">
<!--    <div id="allmap" ref="allmap"></div>-->
    <div>
      <img src="../../assets/map.png" id="mapImg">
    </div>
    <div class="map-box">
      <div class="map-head">
        <i class="iconfont icon-_shaixuan2"></i>
        <span>{{placeProvince}}</span>
        <span>{{placeCity}}</span>
        <!-- <em class="iconfont icon-arrow-right-bold"></em> -->
      </div>
      <van-search
        class="head-search"
        v-model="keywords"
        placeholder="请输入搜索关键词"
      />
      <div
        class="map-list"
        v-for="item in search(keywords)"
        :key="item.id"
        @click="mapShop(item.snet_latitude, item.snet_longitude)"
      >
        <div class="list-head">
          <h2>{{ item.snet_name }}</h2>
          <span
            >{{
              GetDistance(
                latitude,
                longitude,
                item.snet_latitude,
                item.snet_longitude
              )
            }}km</span
          >
        </div>
        <div class="list-address">
          {{ item.snet_address }}
        </div>
        <!-- <div  class="list-tel"> <span class="iconfont icon-telephone" style="color:#50DB89;"></span>{{item.tel}}<span class="tel-btn">点击拨打</span></div> -->
        <div class="list-tel">
          <span class="iconfont icon-telephone" style="color: #50db89"></span
          >{{ item.tel }}
        </div>
      </div>
    </div>

    <!-- 店铺电话弹窗 -->
    <van-action-sheet v-model="telPopupShow" :closeable="false">
      <div class="serve-pop">
        <div class="serve-title">{{ storeTel }}</div>
        <a class="btn-tel" :href="storeTelBtn">拨打</a>
        <div style="height: 10px; background: #f5f7f9"></div>
        <div class="btn-cancel" @click="telPopupShow = false">取消</div>
      </div>
    </van-action-sheet>
    <!-- 加载中 -->
    <van-loading
      type="spinner"
      v-show="loadingShow"
      style="text-align: center"
    />
  </div>
</template>
<script>
import api from "@/utils/api";
export default {
  name: "App",
  data() {
    return {
      ValueData: [],
      loadingShow: true,
      keywords: "",
      latitude: 0,
      longitude: 0,
      placeProvince: "",
      placeCity: "",
      telPopupShow: false,
      storeTel: "", //店铺电话
      storeTelBtn: "", //店铺拨打电话按钮拼接了tel:
    };
  },
  async created() {
    const { data } = await api.mapList();
    this.ValueData = data;
    this.fixedPos();
  },
  methods: {
    telPopup(tel) {
      this.telPopupShow = true;
      this.storeTel = tel;
      this.storeTelBtn = "tel:" + tel;
    },

    search(keywords) {
      // 根据关键字，进行数据的搜索
      const _this = this;
      return _this.ValueData.filter((item) => {
        if (item.snet_name.includes(keywords)) {
          return item;
        }
      });
    },
    mapShop(longitude, latitude) {
      console.log(longitude, latitude);
      let map = new BMap.Map(this.$refs.allmap); // 创建Map实例

      map.enableScrollWheelZoom(true);
      // 创建点标记
      let marker1 = new BMap.Marker(new BMap.Point(latitude, longitude));

      // 在地图上添加点标记
      map.addOverlay(marker1);
      let myIcon = new BMap.Icon(
        "http://wychyq.cn/player/place.png",
        new BMap.Size(32, 32)
      );
      // 创建Marker标注，使用小车图标
      //let pt = new BMap.Point(116.417, 39.909);
      let mk = new BMap.Marker(new BMap.Point(this.longitude, this.latitude), {
        icon: myIcon,
      });
      0;
      // 将标注添加到地图
      map.addOverlay(mk);
      map.centerAndZoom(new BMap.Point(latitude, longitude), 11);
    },
    map() {
      this.loadingShow = false;
      let map = new BMap.Map(this.$refs.allmap); // 创建Map实例
      //定义数组
      let point = new Array();
      let marker = new Array();
      let opts = {};
      let ValueData = this.ValueData;
      for (var i = 0; i < ValueData.length; i++) {
        point[i] = new BMap.Point(
          ValueData[i].snet_longitude,
          ValueData[i].snet_latitude
        );
        opts = {
          position: point[i], // 指定文本标注所在的地理位置
          offset: new BMap.Size(0, -70), // 设置文本偏移量
        };
        marker[i] = new BMap.Marker(point[i]);
        map.addOverlay(marker[i]);
        //map.addOverlay(label);
        marker[i].setAnimation(BMAP_ANIMATION_BOUNCE);
        //跳动的动画
      }
      let myIcon = new BMap.Icon(
        "http://wychyq.cn/player/place.png",
        new BMap.Size(32, 32)
      );
      // 创建Marker标注，使用小车图标
      //let pt = new BMap.Point(116.417, 39.909);
      let mk = new BMap.Marker(new BMap.Point(this.longitude, this.latitude), {
        icon: myIcon,
      });
      // 将标注添加到地图
      map.addOverlay(mk);
    //  map.panTo(new BMap.Point(this.longitude, this.latitude));
      // icon_place
      map.centerAndZoom(new BMap.Point(104.11727, 30.68273), 11);
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      //开启鼠标滚轮缩放
      var scaleCtrl = new BMap.ScaleControl();

      // 添加比例尺控件
      map.addControl(scaleCtrl);
    },
    // fixedPos(lat2,lng2){
    async fixedPos() {
      const _this = this;
      const geolocation = new BMap.Geolocation();
      this.confirmLoading = true;
      await geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          let myGeo = new BMap.Geocoder();
          myGeo.getLocation(
            new BMap.Point(r.point.lng, r.point.lat),
            function (result) {
              if (result) {
                _this.placeProvince=result.addressComponents.province;
                _this.placeCity=result.addressComponents.city,
                _this.latitude = result.point.lat;
                _this.longitude = result.point.lng;
                _this.map();
                //    _this.GetDistance(latitude, longitude, lat2, lng2)

              }
            }
          );
        } else {
          _this.$message.error("failed" + this.getStatus());
        }
      });
    },
    Rad(d) {
      return (d * Math.PI) / 180.0; //经纬度转换成三角函数中度分表形式。
    },
    GetDistance(lat1, lng1, lat2, lng2) {
      //传入两个点的经纬度
      var radLat1 = this.Rad(lat1);
      var radLat2 = this.Rad(lat2);
      var a = radLat1 - radLat2;
      var b = this.Rad(lng1) - this.Rad(lng2);
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000; //输出为公里
      s = s.toFixed(2); //保留小数点后两位小数
      console.log(s);
      return s;
    },
  },

  mounted() {},
};
</script>

<style lang="less" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#allmap {
  height: 500px;
  overflow: hidden;
}
.map-box {
  position: absolute;
  width: 100%;

  top: 500px;
  left: 0;
  bottom: 120px;
  overflow-y: auto;
  background-color: #fff;
}
.map-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: #fff;
}
.map-head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 32px;
  border-bottom: 1px solid #efefef;
  padding: 15px 20px;
  .iconfont {
    font-size: 36px;
    margin-right: 10px;
  }
  span {
    margin-right: 10px;
  }
}
.map-list {
  padding: 15px 20px;
  border-bottom: 1px solid #efefef;
  .list-head {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
    h2 {
      font-size: 32px;
      color: #333333;
      -webkit-flex: 1;
      flex: 1;
    }
    span {
      font-size: 28px;
      color: #666;
    }
  }
  .list-address {
    font-size: 28px;
    color: #999;
  }
  .list-tel {
    font-size: 28px;
    color: #999;
    margin-top: 20px;
    .iconfont {
      margin-right: 15px;
    }
    .tel-btn {
      display: inline-block;
      padding: 4px 8px;
      background: rgb(254, 138, 79);
      font-size: 24px;
      color: #fff;
      border-radius: 4px;
      margin-left: 10px;
    }
  }
}
/* 弹窗 */
.serve-pop {
  .serve-title {
    text-align: center;
    font-size: 34px;
    padding: 42px 0 36px;
    color: #999999;
  }
  .btn-tel {
    color: #333333;
    font-size: 28px;
    text-align: center;
    padding: 36px 0;
    border-top: 1px solid #e1e1e1;
    display: block;
  }
  .btn-cancel {
    color: #333333;
    font-size: 28px;
    text-align: center;
    padding: 36px 0;
    display: block;
  }
}
#mapImg{
  width: 100%;
}
</style>
